var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'EducationalGroupInfo',"size":"xl","headerText":_vm.$t('EducationalGroups.data'),"headerIcon":_vm.educationalGroup.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.fullCode,"title":_vm.$t('EducationalGroups.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupNameAr,"title":_vm.$t('EducationalGroups.nameAr'),"imgName":'EducationalGroups.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupNameEn,"title":_vm.$t('EducationalGroups.nameEn'),"imgName":'EducationalGroups.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupNameUnd,"title":_vm.$t('EducationalGroups.nameUnd'),"imgName":'EducationalGroups.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupDescriptionAr,"title":_vm.$t('EducationalGroups.descriptionAr'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupDescriptionEn,"title":_vm.$t('EducationalGroups.descriptionEn'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupDescriptionUnd,"title":_vm.$t('EducationalGroups.descriptionUnd'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.educationalGroup.educationalGroupStartDate,
          _vm.educationalGroup.educationalGroupStartTime
        ),"title":_vm.$t('EducationalGroups.startDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.educationalGroup.educationalGroupEndDate,
          _vm.educationalGroup.educationalGroupEndTime
        ),"title":_vm.$t('EducationalGroups.endDateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.educationalGroup.educationalGroupTimeZoneNameCurrent,"title":_vm.$t('ConstantsListSelect.TimeZonesName'),"imgName":'TimeZones.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupMaximumStudentsNumber,"title":_vm.$t('EducationalGroups.maximumNumberOfStudents'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupMinimumStudentsNumber,"title":_vm.$t('EducationalGroups.minimumNumberOfStudents'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupClosedStateTypeNameCurrent,"title":_vm.$t('general.state'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalGroupTerminationTypeNameCurrent,"title":_vm.$t('general.type'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalCategoryData.systemComponentsHierarchyData
          .systemComponentsHierarchyNameCurrent,"title":_vm.$t('SystemComponentsHierarchies.educationalActivity'),"imgName":'systemComponentsHierarchies.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroup.educationalCategoryData.systemComponentData
          .systemComponentNameCurrent,"title":_vm.$t('SystemComponents.educationalActivity'),"imgName":'systemComponents.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.educationalGroup.educationalCategoryData
          .educationalCategoryNameCurrent,"title":_vm.$t('EducationalCategories.name'),"imgName":'EducationalCategories.svg'}})],1),_vm._l((_vm.educationalGroup.educationalGroupWeekDayData),function(weekDay,index){return _c('div',{key:index,staticClass:"container-collapse"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("id-" + index)),expression:"`id-${index}`"}],staticClass:"btn btn-lg btn-collapse collapse-data"},[_vm._v(" "+_vm._s(((weekDay.dayNameCurrent) + " - (" + (weekDay.dayWorkTypeNameCurrent) + ")"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":("id-" + index)}},[_c('div',{staticClass:"my-card"},[(weekDay.educationalGroupPlacesData.length == 0)?_c('div',{staticClass:"my-card-no-content"},[_vm._v(" "+_vm._s(_vm.$t("EducationalGroups.thereAreNoSlidesPlaces"))+" ")]):_vm._l((weekDay.educationalGroupPlacesData),function(educationalGroupPlace,indexPlace){return _c('div',{key:indexPlace,staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(indexPlace + 1))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":educationalGroupPlace.placeInfoData.placeNameCurrent,"title":_vm.$t('Places.name'),"imgName":'places.svg'}})],1),(
                educationalGroupPlace.educationalGroupTimesData.length == 0
              )?_c('div',{staticClass:"my-card-no-content"},[_vm._v(" "+_vm._s(_vm.$t("EducationalGroups.thereAreNoSlidesTimes"))+" ")]):_vm._l((educationalGroupPlace.educationalGroupTimesData),function(groupTime,indexGroupTime){return _c('div',{key:indexGroupTime,staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(indexGroupTime + 1))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":groupTime.timeFromByRequestTimeZone,"title":_vm.$t('EducationalGroups.time.from'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":groupTime.timeToByRequestTimeZone,"title":_vm.$t('EducationalGroups.time.to'),"imgName":'time.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":groupTime.timeZoneNameCurrent,"title":_vm.$t('ConstantsListSelect.TimeZonesName'),"imgName":'TimeZones.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":groupTime.appointmentTypeNameCurrent,"title":_vm.$t('ConstantsListSelect.AppointmentTypesName'),"imgName":'AppointmentTypes.svg'}}),_c('div',{staticClass:"my-card col-md-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("lecturers.modelName")))]),(groupTime.employeesInfoData.length == 0)?_c('div',{staticClass:"my-card-no-content"},[_vm._v(" "+_vm._s(_vm.$t("EducationalGroups.thereAreNoLecturers"))+" ")]):_vm._l((groupTime.employeesInfoData),function(employee,indexEmployee){return _c('div',{key:indexEmployee,staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":employee.userNameCurrent,"title":_vm.$t('lecturers.name'),"imgName":'lecturers.svg'}})],1)})],2)],1)])})],2)})],2)])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }