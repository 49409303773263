var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"EducationalGroupChangeFinishType","scrollable":"","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[(
          _vm.educationalGroup.educationalGroupTerminationTypeToken ==
          _vm.TERMINATION_TYPES.NotFinished
        )?_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/finish.svg")}}):_vm._e(),(
          _vm.educationalGroup.educationalGroupTerminationTypeToken ==
          _vm.TERMINATION_TYPES.Finished
        )?_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/unfinish.svg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("changeFinishType"))+" ")])]},proxy:true}])},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(
      _vm.educationalGroup.educationalGroupTerminationTypeToken ==
      _vm.TERMINATION_TYPES.NotFinished
    )?_c('h4',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("areYouSureToFinishStart"))+" "),_c('span',{staticClass:"co-red"},[_vm._v(" "+_vm._s(_vm.educationalGroup.educationalGroupNameCurrent)+" ")]),_vm._v(" "+_vm._s(_vm.$t("areYouSureToFinishEnd"))+" ")]):_vm._e(),(
      _vm.educationalGroup.educationalGroupTerminationTypeToken ==
      _vm.TERMINATION_TYPES.Finished
    )?_c('h4',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("areYouSureToUnfinishStart"))+" "),_c('span',{staticClass:"co-red"},[_vm._v(" "+_vm._s(_vm.educationalGroup.educationalGroupNameCurrent)+" ")]),_vm._v(" "+_vm._s(_vm.$t("areYouSureToUnfinishEnd"))+" ")]):_vm._e(),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.changeFinishType.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")]),_c('button',{staticClass:"btn btn-cancel",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide('EducationalGroupChangeFinishType')}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }