var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"EducationalGroupChangeCloseType","scrollable":"","centered":"","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[(
          _vm.educationalGroup.educationalGroupClosedStateTypeToken ==
          _vm.CLOSED_STATE_TYPES.Open
        )?_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/closeGroup.svg")}}):_vm._e(),(
          _vm.educationalGroup.educationalGroupClosedStateTypeToken ==
          _vm.CLOSED_STATE_TYPES.Closed
        )?_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/openGroup.svg")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("changeCloseOpenType"))+" ")])]},proxy:true}])},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(
      _vm.educationalGroup.educationalGroupClosedStateTypeToken ==
      _vm.CLOSED_STATE_TYPES.Open
    )?_c('h4',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("areYouSureToCloseStart"))+" "),_c('span',{staticClass:"co-red"},[_vm._v(" "+_vm._s(_vm.educationalGroup.educationalGroupNameCurrent)+" ")]),_vm._v(" "+_vm._s(_vm.$t("areYouSureToCloseEnd"))+" ")]):_vm._e(),(
      _vm.educationalGroup.educationalGroupClosedStateTypeToken ==
      _vm.CLOSED_STATE_TYPES.Closed
    )?_c('h4',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("areYouSureToOpenStart"))+" "),_c('span',{staticClass:"co-red"},[_vm._v(" "+_vm._s(_vm.educationalGroup.educationalGroupNameCurrent)+" ")]),_vm._v(" "+_vm._s(_vm.$t("areYouSureToOpenEnd"))+" ")]):_vm._e(),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.changeCloseType.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")]),_c('button',{staticClass:"btn btn-cancel",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide('EducationalGroupChangeCloseType')}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }